import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import * as NavMenu from './navmenu.theme';

class Menu extends Component {
  static propTypes = {
    location: PropTypes.object,
    items: PropTypes.array,
    isOpen: PropTypes.bool,
    handleNavToggle: PropTypes.func,
  }

  renderLink(item) {
    const { location, handleNavToggle } = this.props;
    const { path, label } = item;
    const activeClass = location.pathname === item.path ? 'is-active' : '';

    return (
      <NavMenu.ListItem key={`menu-item-${label}`} className={activeClass}>
        <NavMenu.NavLink
          to={path}
          onClick={() => handleNavToggle()}
        >
          { label }
        </NavMenu.NavLink>
      </NavMenu.ListItem>
    );
  }

  render() {
    const { isOpen, items } = this.props;
    const openClass = isOpen ? 'is-open' : '';

    return (
      <NavMenu.Container className={openClass}>
        <NavMenu.List>
          { items.map((item) => this.renderLink(item)) }
        </NavMenu.List>
      </NavMenu.Container>
    );
  }
}

export default withRouter(Menu);
