import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
  NavToggleContainer,
  NavToggleBar,
} from './navtoggle.theme';

class NavToggle extends PureComponent {
  static propTypes = {
    handleNavToggle: PropTypes.func,
    isOpen: PropTypes.bool,
  }

  render() {
    const { handleNavToggle, isOpen } = this.props;
    const hiddenClass = isOpen ? 'is-hidden' : '';
    const rotateRight = isOpen ? 'rotate-right' : '';
    const rotateLeft = isOpen ? 'rotate-left' : '';

    return (
      <NavToggleContainer onClick={() => handleNavToggle()}>
        <NavToggleBar className={rotateRight} />
        <NavToggleBar className={hiddenClass} />
        <NavToggleBar className={rotateLeft} />
      </NavToggleContainer>
    );
  }
}

export default NavToggle;
