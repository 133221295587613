export const baseElement = '#armrbuilders';

// Hosts
export const productionHostName = '';
export const stagingHostName = '';

// App Config
export const sentry_dsn = '';


export const googleAnalyticsID = 'UA-139729994-1';
