import styled from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';
import zIndex from 'theme/zIndex';

const borderWidthXS = '.25rem';
const borderWidth = '.75rem';

export const Wrapper = styled.div`
  overflow-x: hidden;
`;

export const Border = styled.div`
  border: ${borderWidthXS} solid ${colors.white};
  height: 100vh;
  position: fixed;
  pointer-events: none;
  width: 100vw;
  z-index: ${zIndex.border};

  @media (min-width: ${media.smMin}) {
    border-width: ${borderWidth};
  }
`;

export const BodyContainer = styled.div`
  background: ${colors.white};
  min-height: 100vh;
  width: 100vw;
  z-index: ${zIndex.body};
`;
