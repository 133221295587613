import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'theme/colors';
import media from 'theme/media';
import zIndex from 'theme/zIndex';

const navHeight = '100px';
const navHeightXs = '70px';

export const Wrapper = styled.div`
  align-items: center;
  background: #fff;
  color: #000;
  display: flex;
  height: ${navHeightXs};
  flex-shrink: 0;
  left: 0;
  position: fixed;
  right: 0;
  transition: all .3s ease;
  z-index: ${zIndex.header};

  @media (min-width: ${media.smMin}) {
    background: transparent;
    color: #fff;
    height: ${navHeight};

    &.is-small {
      background: #fff;
      color: ${colors.brandPrimary};
      height: ${navHeightXs};

      [class*='__Flex'] {
        padding: 0 3em;
      }

      .is-active {
        border-bottom-color: ${colors.brandPrimary};
      }
    }
  }
`;

export const Flex = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 1.5em;
  width: 100%;

  @media (min-width: ${media.smMin}) {
    padding: 3em 3em 0;
  }
`;

export const Brand = styled.div`
  margin-right: auto;
  width: auto;
`;

export const BrandLink = styled(Link)`
  color: inherit;
  font-size: 20px;
  font-weight: 500;

  &:hover {
    color: ${colors.secondary};
  }
`;

export const BrandLogo = styled.img`
  padding: 0;
  width: 80px;
  transition: width .3s ease;

  @media (min-width: ${media.smMin}) {
    width: 100px;

    &.is-small {
      width: 80px;
    }
  }
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
`;

export const Item = styled.li`
  line-height: ${navHeight};
  margin: 0 .5em;
  padding: 0;

  @media (min-width: ${media.smMin}) {
    display: inline-block;
  }
`;

export const NavLink = styled(Link)`
  border-bottom: 3px solid transparent;
  color: inherit;
  display: inline;
  font-weight: inherit;
  font-size: 16px;
  line-height: ${navHeight};
  margin: 0 .5em;
  padding: 0 0 .5em 0;
  transition: all .2s ease;

  &.is-active {
    border-bottom-color: ${colors.white};
  }

  &:hover {
    color: ${colors.grayLight};

    &.is-active {
      border-bottom-color: ${colors.grayLight};
    }
  }
`;
