import styled from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';
import Wrapper from 'components/Wrapper';

export const Container = styled.div`
  background: ${(props) => props.background ? `${colors.brandPrimary} url(${props.background})` : colors.brandSecondary};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  height: 120vh;
  width: 100vw;
  overflow: hidden;

  &.is-small {
    min-height: 500px;
    height: 80vh;
  }

  &.is-medium {
    min-height: 500px;
    height: 80vh;
  }

  @media (min-width: ${media.smMin}) {
    &.is-small {
      height: 50vh;
    }

    &.is-medium {
      height: 70vh;
    }
  }
`;

export const HeroWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;

  &.is-small {
    min-height: 500px;
    height: 80vh;
  }

  &.is-medium {
    min-height: 500px;
    height: 80vh;
  }

  @media (min-width: ${media.smMin}) {
    &.is-small {
      height: 50vh;
    }

    &.is-medium {
      height: 70vh;
    }
  }

  @media (min-width: ${media.mdMin}) {
    max-width: 900px;
  }

  @media (min-width: ${media.lgMin}) {
    max-width: 1050px;
  }

  @media (min-width: ${media.xlMin}) {
    max-width: 1400px;
  }
`;

export const Tagline = styled.h1`
  color: ${colors.grayLight};
  font-family: Brown;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0 0 1rem 0;

  @media (min-width: ${media.xlMin}) {
    font-size: 1.5rem;
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  line-height: 1.25;
  padding-bottom: 15px;

  @media (min-width: ${media.smMin}) {
    font-size: 3rem;
  }

  @media (min-width: ${media.mdMin}) {
    font-size: 4rem;
  }

  @media (min-width: ${media.xlMin}) {
    font-size: 6rem;
  }
`;

export const Lead = styled.p`
  color: ${colors.greenLight};
  font-size: 1.25rem;
  margin-top: 1.5rem;

  @media (min-width: ${media.xlMin}) {
    font-size: 2rem;
  }
`;
