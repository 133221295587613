import React, { PureComponent } from 'react';
import colors from 'theme/colors';
import logoSlogan from 'assets/images/brand/logo-slogan.svg';
import Grid from 'components/Grid';
import Wrapper from 'components/Wrapper';
import * as Footer from './footer.theme';

export default class extends PureComponent {
  renderNavList({ items, social }) {
    if (social) {
      return social.map((item) => (
        <Footer.Item key={item.label}>
          { this.renderA(item) }
        </Footer.Item>
      ));
    }

    return items.map((item) => (
      <Footer.Item key={item.label}>
        { this.renderNavItem(item) }
      </Footer.Item>
    ));
  }

  renderNavItem(item) {
    return (
      <Footer.FooterLink key={item.label} to={item.path}>
        { item.label }
      </Footer.FooterLink>
    );
  }

  renderA(item) {
    return (
      <Footer.FooterA key={item.label} href={item.url} target="_blank">
        { item.label }
      </Footer.FooterA>
    );
  }

  render() {
    const items = [
      { path: '/about', label: 'About' },
      { path: '/team', label: 'Team' },
      { path: '/projects', label: 'Projects' },
      { path: '/testimonials', label: 'Testimonials' },
      { path: '/contact', label: 'Contact' },
    ];

    const social = [
      // { url: 'https://www.instagram.com/armrbuilders', label: 'Facebook' },
      { url: 'https://www.instagram.com/armrbuilders', label: 'Instagram' },
      { url: 'https://twitter.com/armrbuilders', label: 'Twitter' },
    ];

    const date = new Date();
    const year = date.getFullYear();
    const copyright = `${year} ARMR Builders. All Rights Reserved.`;

    return (
      <Footer.Container key="footer">
        <Wrapper style={{ padding: 0 }}>
          <Footer.Grid>
            <Footer.Wrapper>
              <Grid>
                <Footer.List>
                  { this.renderNavList({ items }) }
                </Footer.List>

                <Footer.List color={colors.gray}>
                  { this.renderNavList({ social }) }
                </Footer.List>
              </Grid>

              <Footer.Logo id="footer-logo" src={logoSlogan} alt="logo" />

              <Footer.Copyright>
                &copy;&nbsp;
                { copyright }
              </Footer.Copyright>
            </Footer.Wrapper>

            <Footer.Wrapper background={colors.grayDarkest}>
              <h5>
                We&apos;d love to hear from you. Contact us at&nbsp;
                <br className="is-desktop" />

                <a href="mailto:info@armrbuilders.com">
                  info@armrbuilders.com
                </a>
              </h5>
            </Footer.Wrapper>
          </Footer.Grid>
        </Wrapper>
      </Footer.Container>
    );
  }
}
