import styled from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';

export const NavToggleContainer = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  height: 21px;
  margin-left: auto;
  width: 25px;

  &::after {
    display: none;
  }

  &:hover {
    > div {
      background: ${colors.secondary};
    }
  }

  @media (min-width: ${media.smMin}) {
    display: none;
  }
`;

export const NavToggleBar = styled.div`
  background: ${colors.brandPrimary};
  border-radius: 10px;
  height: 3px;
  transition: background .2s ease-in-out;
  width: 100%;
  transition: all .3s ease;

  &.is-hidden {
    opacity: 0;
  }

  &.rotate-right {
    transform: translateY(9px) rotate(45deg);
  }

  &.rotate-left {
    transform: translateY(-9px) rotate(-45deg);
  }
`;
