import styled, { css } from 'styled-components';
import colors from 'theme/colors';
import media from 'theme/media';
import zIndex from 'theme/zIndex';

import { Link } from 'react-router-dom';

export const Container = styled.div`
  background: ${colors.white};
  color: transparent;
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding: 2em;
  padding-top: 100px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all .5s ease-in-out;
  transform: translateY(-100%);
  width: 100vw;
  z-index: ${zIndex.menu};

  &.is-open {
    color: ${colors.brandPrimary};
    transform: translateY(0);
  }

  @media (min-width: ${media.smMin}) {
    display: none;
  }
`;

export const List = styled.div`
  font-size: 1.75em;
  line-height: 2.5em;
`;

export const ListItem = styled.div`
  font-size: inherit;
  font-weight: inherit;
  display: block;
  margin: 0;
  padding: 0;
`;

const navMenuLinkStyles = css`
  color: inherit;
  display: block;
`;

export const NavLink = styled(Link)`
  ${navMenuLinkStyles}
`;
