export default {
  xsMax: '767px',
  smMin: '768px',
  smMax: '899px',
  mdMin: '900px',
  mdMax: '1279px',
  lgMin: '1280px',
  lgMax: '1799px',
  xlMin: '1800px',
};
