import React, { Component } from 'react';
import AppRouter from 'router';
import { Helmet } from 'react-helmet';
import colors from 'theme/colors';

import Nav from 'components/Nav';
import Footer from 'components/Footer';
import ScrollToTop from 'components/ScrollToTop';

import favicon16 from 'assets/images/icon-16x16.png';
import favicon32 from 'assets/images/icon-32x32.png';

import * as Layout from 'components/Layout';

class MainApp extends Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const nav = document.getElementById('nav');
    const logo = document.getElementById('nav-logo');

    if (window.pageYOffset > 75) {
      nav.classList.remove('is-transparent');
      nav.classList.add('is-small');
      logo.classList.add('is-small');
    } else if (window.pageYOffset === 0) {
      nav.classList.add('is-transparent');
      nav.classList.remove('is-small');
      logo.classList.remove('is-small');
    }
  }

  render() {
    const description = 'THINKING DIFFERENTLY. We are creating quality urban lifestyles, building stronger communities.';

    return (
      <Layout.Wrapper>
        <Helmet
          defaultTitle="ARMR Builders"
          titleTemplate="ARMR Builders | %s"
        >
          <meta name="description" content={description} />
          <meta name="theme-color" content={colors.brandPrimary} />
          <link rel="apple-touch-icon" sizes="180x180" href={favicon32} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
          <link rel="mask-icon" href={favicon32} color={colors.brandPrimary} />
          <meta name="msapplication-TileColor" content={colors.brandPrimary} />
        </Helmet>

        <Layout.Border />

        <Nav />

        <Layout.BodyContainer>
          <ScrollToTop>
            <AppRouter />
          </ScrollToTop>
        </Layout.BodyContainer>

        <Footer />
      </Layout.Wrapper>
    );
  }
}

export default MainApp;
