import { createGlobalStyle } from 'styled-components';
import fonts from 'theme/fonts';
import media from 'theme/media';
import colors from 'theme/colors';

// Global styling injection, do not over use.

/* eslint no-unused-expressions: 0 */
// This can't be easily tested so we ignore it in package.json.jest (maybe int he future we could check the dom for <style> via JSDOM)
export const GlobalStyles = createGlobalStyle`
  * {
    font-size: inherit;
    margin: 0;
    max-width: none;
    -webkit-font-smoothing: auto;
  }

  body {
    background: white;
    font-size: 14px;
    line-height: 1rem;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    ${fonts.body}
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  small {
    line-height: 1.2em;
  }

  h1,
  .h1 {
    ${fonts.h1}

    @media (max-width: ${media.smMax}) {
      ${fonts.h1Mobile}
    }
  }

  h2,
  .h2 {
    ${fonts.h2}
    font-weight: 300;

    @media (max-width: ${media.smMax}) {
      ${fonts.h2Mobile}
    }
  }

  h3,
  .h3 {
    ${fonts.h3}

    @media (max-width: ${media.smMax}) {
      ${fonts.h3Mobile}
    }
  }

  h4,
  .h4 {
    ${fonts.h4}

    @media (max-width: ${media.smMax}) {
      ${fonts.h4Mobile}
    }
  }

  h5,
  .h5 {
    ${fonts.h5}

    @media (max-width: ${media.smMax}) {
      ${fonts.h5Mobile}
    }
  }

  small,
  .small {
    ${fonts.small}
  }

  i.icon-chevron {
    display: inline-block;
    margin-left: 15px;
    margin-right: 5px;
    transform: rotate(90deg);

    &:before,
    &:after {
      content: '';
      width: 6px;
      height: 1px;
      position: absolute;
      bottom: 0;
      margin: auto 0;
      right: 0;
      top: 1px;
      box-shadow: inset 0 0 0 32px;
      transform-origin: right;
    }

    &:before {
      transform: rotate(50deg);
    }

    &:after {
      transform: rotate(-50deg);
    }

    &.open {
      &:before,
      &:after {
        right: 4px;
      }
    }

    &.close {
      &:before,
      &:after {
        right: 8px;
      }

      &:before {
        transform: rotate(230deg);
      }

      &:after {
        transform: rotate(-230deg);
      }
    }
  }

  a,
  button {
    &:focus {
      outline: none;
    }
  }

  a {
    color: ${colors.blueLight};
    text-decoration: none;
    transition: color .2s ease;

    &:hover {
      color: ${colors.blueDark};
      text-decoration: none;
    }
  }

  button {
    appearance: none;
    border: none;
    box-sizing: border-box;
    cursor: pointer;
  }

  .is-mobile {
    display: block !important;
  }

  .is-desktop {
    display: none !important;
  }

  @media (min-width: ${media.smMin}) {
    .is-mobile {
      display: none !important;
    }

    .is-desktop {
      display: block !important;
    }
  }
`;
