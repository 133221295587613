import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'theme/colors';
import media from 'theme/media';

export const Container = styled.div`
  background: ${colors.brandPrimary};
  color: $${colors.white};

  @media (min-width: ${media.smMin}) {
    background: linear-gradient(to right, ${colors.brandPrimary}, ${colors.brandPrimary} 65%, ${colors.grayDarkest} 35%);
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1rem 0;

  @media (min-width: ${media.smMin}) {
    grid-template-columns: 60% 40%;
    grid-gap: 0;
    justify-items: stretch;
  }
`;

export const Wrapper = styled.div`
  background: ${(props) => props.background ? props.background : 'transparent'};
  color: ${colors.white};
  padding: 3rem 2rem;
  position: relative;

  h5 {
    line-height: 1.5;
  }

  a {
    &:hover {
      color: ${colors.grayDark};
    }
  }

  @media (min-width: ${media.smMin}) {
    padding: 6rem 4rem;
  }
`;

export const List = styled.ul`
  color: ${(props) => props.color ? props.color : colors.white};
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const Item = styled.li`
  color: inherit;
  margin: 0 0 .75em;
  padding: 0;

  a {
    &:hover {
      color: inherit;
    }
  }
`;

export const FooterLink = styled(Link)`
  color: inherit;
  font-weight: 600;
  transition: all .2s ease;

  &:hover {
    color: ${colors.grayLight};
  }
`;

export const FooterA = styled.a`
  color: inherit;
  font-weight: 600;
  transition: all .2s ease;

  &:hover {
    color: ${colors.grayLight};
  }
`;

export const Logo = styled.img`
  margin: 2.5em 0 1.5em;
  max-width: 350px;
  width: 100%;
`;

export const Copyright = styled.div`
  color: ${colors.white};
  font-size: 1em;
`;
