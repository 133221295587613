import styled from 'styled-components';
import media from 'theme/media';

export default styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 2rem 0;

  @media (min-width: ${media.smMin}) {
    grid-template-columns: 50% 50%;
    grid-gap: 3rem 0;
    justify-items: stretch;
  }
`;
