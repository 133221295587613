import styled from 'styled-components';
import media from 'theme/media';

export default styled.div`
  flex: 1 0 auto;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2em;
  width: 100%;

  @media (min-width: ${media.smMin}) {
    max-width: 800px;
  }

  @media (min-width: ${media.mdMin}) {
    max-width: 1000px;
  }

  @media (min-width: ${media.lgMin}) {
    max-width: 1200px;
  }

  @media (min-width: ${media.xlMin}) {
    max-width: 1600px;
  }
`;
