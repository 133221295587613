import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { Parallax } from 'react-scroll-parallax';
import homeBg from 'assets/images/bg/homepage@2x.jpg';
import aboutBg from 'assets/images/bg/about@2x.jpg';
import teamBg from 'assets/images/bg/team@2x.jpg';

import * as Hero from './hero.theme';

export default class extends PureComponent {
  static propTypes = {
    page: PropTypes.string,
    project: PropTypes.object,
  }

  getData(page) {
    const data = {
      home: {
        bg: homeBg,
        tagline: 'Thinking differently',
        title: 'We are creating quality urban lifestyles, and building stronger communities',
        lead: 'We are ARMR Builders. We create beautiful West Coast inspired homes.',
      },
      about: {
        bg: aboutBg,
        tagline: 'Who we are',
        title: 'Our talented team works with a balanced approach to create beautiful projects.',
        lead: 'We are done with the old and are in with the new. We are going to change the industry.',
      },
      team: {
        bg: teamBg,
        tagline: 'Our team',
        title: 'ARMR Builders was co-founded by four partners: Am, Rick, Manpreet, Raj.',
        lead: 'You probably figured out how we came up with our name.',
      },
      projects: {
        bg: '',
        tagline: 'Seeing is Believing',
        title: 'Select completed projects under the ARMR portfolio.',
        lead: '',
      },
      testimonials: {
        bg: '',
        tagline: 'Testimonials',
        title: 'We pride ourselves on building positive relationships.',
        lead: 'Don’t take our word for it, hear what they have to say about ARMR.',
      },
      contact: {
        bg: '',
        tagline: 'Get in touch',
        title: 'We love to talk about building and real estate.',
        lead: '',
      },
      notFound: {
        bg: homeBg,
        tagline: 'Error - 404',
        title: "Sorry, we couldn't find the page you're looking for.",
        lead: "Head back to our home page to find the content you're looking for.",
      },
    };
    return data[page];
  }

  render() {
    const { page, project } = this.props;
    let data = {};

    if (page) { data = this.getData(page); }
    if (project) { data = project; }

    const smallClass = page === 'projects' || page === 'contact' ? 'is-small' : '';
    const mediumClass = page === 'testimonials' ? 'is-medium' : '';

    return (
      <Fade>
        <Hero.Container key={data.title} background={data.bg} className={`${smallClass} ${mediumClass}`}>
          <Hero.HeroWrapper className={`${smallClass} ${mediumClass}`}>
            <Parallax y={[-40, 40]}>
              <Fade left cascade>
                <div>
                  <Hero.Tagline>
                    { data.tagline }
                  </Hero.Tagline>
                  <Hero.Title>
                    { data.title }
                  </Hero.Title>
                  {
                    data.lead && (
                      <Hero.Lead>
                        { data.lead }
                      </Hero.Lead>
                    )
                  }
                </div>
              </Fade>
            </Parallax>
          </Hero.HeroWrapper>
        </Hero.Container>
      </Fade>
    );
  }
}
