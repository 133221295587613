import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import Hero from 'components/Hero';

class NotFoundPage extends PureComponent {
  render() {
    const description = '404 - page not found.';

    return (
      <>
        <Helmet title="Not Found">
          <meta name="description" content={description} />
        </Helmet>

        <Hero page="notFound" />
      </>
    );
  }
}

export default NotFoundPage;
