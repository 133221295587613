import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import logo from 'assets/images/brand/logo.svg';

import NavToggle from './Toggle';
import NavMenu from './Menu';
import * as NavTheme from './nav.theme';

class Nav extends PureComponent {
  static propTypes = {
    location: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.state = {
      isNavOpen: false,
    };

    this.handleNavToggle = this.handleNavToggle.bind(this);
    this.closeNavToggle = this.closeNavToggle.bind(this);
  }

  handleNavToggle() {
    this.setState((prevState) => ({
      isNavOpen: !prevState.isNavOpen,
    }));
  }

  closeNavToggle() {
    const { isNavOpen } = this.state;
    if (isNavOpen) {
      this.setState({
        isNavOpen: false,
      });
    }
  }

  renderNavList(items) {
    return items.map((item) => (
      <NavTheme.Item key={item.label}>
        { this.renderNavItem(item) }
      </NavTheme.Item>
    ));
  }

  renderNavItem(item) {
    const { location: { pathname } } = this.props;
    const activeClass = pathname === item.path ? 'is-active' : '';

    return (
      <NavTheme.NavLink key={item.label} to={item.path} className={activeClass}>
        { item.label }
      </NavTheme.NavLink>
    );
  }

  render() {
    const { isNavOpen } = this.state;
    const openClass = isNavOpen ? 'is-open' : '';

    const items = [
      { path: '/about', label: 'About' },
      { path: '/team', label: 'Team' },
      { path: '/projects', label: 'Projects' },
      { path: '/testimonials', label: 'Testimonials' },
      { path: '/contact', label: 'Contact' },
    ];

    return (
      <>
        <NavTheme.Wrapper id="nav" className={openClass}>
          <NavTheme.Flex>
            <NavTheme.Brand>
              <NavTheme.BrandLink to="/" onClick={() => this.setState({ isNavOpen: false })}>
                <NavTheme.BrandLogo id="nav-logo" src={logo} alt="logo" />
              </NavTheme.BrandLink>
            </NavTheme.Brand>

            <NavTheme.List className="is-desktop">
              { this.renderNavList(items) }
            </NavTheme.List>

            <NavToggle handleNavToggle={this.handleNavToggle} isOpen={isNavOpen} />
          </NavTheme.Flex>
        </NavTheme.Wrapper>

        <NavMenu items={items} handleNavToggle={this.handleNavToggle} isOpen={isNavOpen} />
      </>
    );
  }
}

export default withRouter(Nav);
