import { createGlobalStyle } from 'styled-components';
import BrownBold from 'assets/fonts/brown-bold.woff2';
import BrownRegular from 'assets/fonts/brown-regular.woff2';
import FeijoaMedium from 'assets/fonts/feijoa-medium.woff2';

export const FontFaces = createGlobalStyle`
  @font-face {
    font-family: Brown;
    font-style: normal;
    font-weight: 600;
    src: url('${BrownBold}') format('woff');
  }
  @font-face {
    font-family: Brown;
    font-style: normal;
    font-weight: 400;
    src: url('${BrownRegular}') format('woff');
  }
  @font-face {
    font-family: Feijoa;
    font-style: normal;
    font-weight: 400;
    src: url('${FeijoaMedium}') format('woff');
  }
`;

export default {
  body: `
    font-family: Brown, Helvetica, Arial, sans-serif;
    font-weight: 400;
    margin: 0;
  `,

  h1: `
    font-family: Feijoa;
    font-size: 60px;
  `,

  h1Mobile: `
    font-family: Feijoa;
    font-size: 40px;
  `,

  h2: `
    font-family: Feijoa;
    font-size: 40px;
    line-height: 1.1;
  `,

  h2Mobile: `
    font-family: Feijoa;
    font-size: 38px;
  `,

  h3: `
    font-family: Feijoa;
    font-size: 35px;
    font-weight: 400;
    line-height: 1.1;
  `,

  h3Mobile: `
    font-family: Feijoa;
    font-weight: 400;
    font-size: 28px;
  `,

  h4: `
    font-family: Feijoa;
    font-weight: 400;
    font-size: 26px;
  `,

  h4Mobile: `
    font-family: Feijoa;
    font-weight: 400;
    font-size: 24px;
  `,

  h5: `
    font-family: Brown;
    font-weight: 600;
    font-size: 22px;
  `,

  h5Mobile: `
    font-family: Brown;
    font-weight: 600;
    font-size: 18px;
  `,

  h6: `
    font-family: Brown;
    font-weight: 600;
    font-size: 20px;
  `,

  h6Mobile: `
    font-family: Brown;
    font-weight: 600;
    font-size: 16px;
  `,

  small: `
    font-size: 14px;
  `,
};
