export default {
  // Project Colours
  brandPrimary: '#373E48',
  brandSecondary: '#51636F',

  blueLight: '#39b1e6',
  blueDark: '#1e2533',
  gray: '#B6BCC1',
  grayLight: '#d1cfcf',
  grayLightest: '#F3F1F2',
  grayDark: '#404040',
  grayDarkest: '#25262A',
  green: '#37b844',
  greenLight: '#64ea72',
  peach: '#EEECE1',
  black: '#0d0e0e',
  white: '#fff',

  // Social
  facebookBlue: '#3b5998',
  linkedinBlue: '#0077b5',
  instagramPurple: '#833ab4',
};
