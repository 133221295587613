import React, {
  Fragment,
  StrictMode,
  Suspense,
  lazy,
} from 'react';
import { Switch, Route } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { FontFaces } from 'theme/fonts';
import { GlobalStyles } from 'theme/globalStyles';
import NotFoundPage from 'containers/NotFoundPage';
import { reactStrictMode } from 'config/dev';
import { getEnvironment } from 'utils';
import ErrorBoundary from 'components/ErrorBoundary';

const HomePage = lazy(() => import('containers/HomePage'));
const AboutPage = lazy(() => import('containers/AboutPage'));
const TeamPage = lazy(() => import('containers/TeamPage'));
const ProjectsPage = lazy(() => import('containers/ProjectsPage'));
const TestimonialsPage = lazy(() => import('containers/TestimonialsPage'));
const ContactPage = lazy(() => import('containers/ContactPage'));

export default function Router() {
  const routes = [
    <Fragment key="router">
      <FontFaces />
      <GlobalStyles />
      <ParallaxProvider>
        <Suspense fallback={<div>Loading...</div>}>
          <ErrorBoundary>
            <Switch>
              <Route exact path="/" component={(props) => <HomePage {...props} />} />
              <Route exact path="/about" component={(props) => <AboutPage {...props} />} />
              <Route exact path="/team" component={(props) => <TeamPage {...props} />} />
              <Route path="/projects" component={(props) => <ProjectsPage {...props} />} />
              <Route exact path="/testimonials" component={(props) => <TestimonialsPage {...props} />} />
              <Route exact path="/contact" component={(props) => <ContactPage {...props} />} />

              <Route component={NotFoundPage} />
            </Switch>
          </ErrorBoundary>
        </Suspense>
      </ParallaxProvider>
    </Fragment>,
  ];
  return reactStrictMode && getEnvironment(window.location.hostname) !== 'production'
    ? <StrictMode>{ routes }</StrictMode>
    : routes;
}
